import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./privacyTermsThemes.module.css"
import queryString from "query-string"


const TermsPage = (props) => {

    let top_margin = null;
    let backgroundStyle = null;
    let from_app = true
    if (typeof window !== "undefined") {
        const search = window.location.search;
        const params = queryString.parse(search);
        from_app = params.from_app !== undefined && parseInt(params.from_app) === 1;
        // top_margin = from_app ? "5% !important" : "14%";
        // backgroundStyle = from_app ? {backgroundImage: "none"} : {backgroundImage: background};
    }

    return (
        <Layout>
            <SEO title="Paths Terms" />
            <div className={from_app ? `${styles.privacyTermsContainer} ${styles.privacyTermsContainerFromApp}` : styles.privacyTermsContainer}>
                <div className={from_app ? `${styles.privacyTermsContent} ${styles.privacyTermsContentFromApp}` : styles.privacyTermsContent}>
                    <h3>Paths Terms and Conditions</h3>
                    <p>
                        The following Terms of Service (the “Terms”) are created as a binding agreement between you, 
                        the user, and Paths Game Limited (“we,” “us” or Paths). Your use of our mobile application 
                        currently known as “Paths”, website located at <a href="http://pathsapp.co">http://pathsapp.co</a>, and 
                        other interactive software products and services that we operate (collectively our “Service”) is governed 
                        by these Terms.
                    </p>
                    <h4>1. Agreement </h4>
                    <p>
                        Please ensure you read and understand these Terms before using our Service. 
                        This Agreement is a legal contract made between you and us. BY INSTALLING, 
                        USING OR OTHERWISE ACCESSING OUR SERVICE, YOU AGREE TO THESE TERMS. IF YOU DO 
                        NOT AGREE TO THESE TERMS, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS OUR SERVICE.
                    </p>
                    <p>
                        WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR REMOVE ANY FEATURES OF
                        OUR SERVICE AT ANY TIME AT OUR SOLE DISCRETION. We will notify you of changes to
                        these Terms (which will be posted on our website) and you will be given reasonable time to
                        access, read and consider any changes. THESE CHANGES WILL TAKE EFFECT AT THE
                        TIME THEY ARE POSTED OR AS OTHERWISE REQUIRED BY APPLICABLE LAW. BY
                        CONTINUING TO USE OUR SERVICES, YOU ACCEPT SUCH CHANGES TO THE TERMS.
                    </p>    
                    <p>    
                        YOU ARE RESPONSIBLE FOR REVIEWING OUR WEBSITES REGULARLY TO OBTAIN
                        TIMELY NOTICE OF ANY SUCH CHANGES. IF YOU DO NOT AGREE TO THESE TERMS,
                        AS AMENDED AND UPDATED FROM TIME TO TIME, DO NOT CONTINUE TO USE OR
                        ACCESS THE SERVICE. YOU CAN TERMINATE THIS AGREEMENT IMMEDIATELY ON
                        NOTICE TO US WITHOUT PENALTY IF YOU DO NOT AGREE WITH THE UPDATED
                        TERMS.
                    </p>
                    <h4>2. Eligibility and Registration</h4>
                    <p>
                        By creating an account and using our Service, you make the following representations:<br/><br/>
                        a) You are aged 12 years or older, or at the age of majority in your country of residence, or
                        have permission from a parent or legal guardian who has reviewed and agreed to these
                        Terms,<br/>
                        b) You are able to form a legally binding contract,<br/>
                        c) You have never been banned from using our Service.<br/>
                        By registering as a member, you warrant to us that you comply with all of these requirements. If you breach this warranty, without limitation to our other rights and remedies, we are entitled to terminate this Agreement under section 6 below.
                        <br/><br/>
                        Without limiting any other remedies available to us (including our rights to terminate in section 6 below), WE RESERVE THE RIGHT AT ANY TIME TO SUSPEND OR REMOVE ANY
                        CONTENT YOU SUBMIT FROM OUR SERVICE AT OUR SOLE DISCRETION. TO THE
                        MAXIMUM EXTENT PERMITTED BY LAW, IF WE DO REMOVE CONTENT YOU SUBMIT WE
                        WILL NOT BE LIABLE TO YOU IN ANY WAY.
                    </p>
                    <h4>3. Use of our Service</h4>
                    <p>
                        <b>Limited License</b><br/>
                        In accordance with our Terms, you may access and use our Service for your personal and
                        non-commercial use.This includes the use of our Services running operating systems on which the Services were designed to operate. 
                        OUR SERVICE IS LICENSED, NOT SOLD, TO YOU. Therefore, you may not modify, copy, distribute, transmit, display, perform, reproduce, publish, create derivative works from or adapt, sell, 
                        rent, lease, sublicense, or otherwise transfer or make available our Service or any portion thereof.
                    </p>
                    <p>
                        USE OF OUR SERVICE IS WITH OUR PERMISSION, WHICH MAY BE REVOKED AT ANY
                        TIME, FOR ANY REASON, IN OUR SOLE DISCRETION. YOU HAVE NO RIGHTS TO OR IN
                        OUR SERVICE UNLESS EXPRESSLY GRANTED TO YOU UNDER THESE TERMS. YOUR
                        LICENSE WILL BE AUTOMATICALLY REVOKED AND TERMINATED IF YOU BREACH
                        THESE TERMS IN ANY PART.
                    </p>
                    <p>
                        Without limiting the foregoing, you acknowledge that you have no right to sell or otherwise
                        transfer any of the virtual items, virtual currency, points, or any other content or information from our Service, 
                        in whole or in part, on any other third-party sites or services in return for anything of value. 
                        YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE NO OWNERSHIP OR OTHER PROPERTY INTEREST IN YOUR ACCOUNT, 
                        ALL RIGHTS IN AND TO YOUR ACCOUNT ARE AND SHALL FOREVER BE OWNED BY US.
                    </p>
                    <p>
                        <b>Additional Terms</b><br/>
                        Parts of our Service are subject to and governed by additional terms, conditions, rules, or
                        guidelines on how and when these parts can be used (“Additional Terms”). These Additional
                        Terms will be made available to you through the applicable service, and your acceptance of
                        these Additional Terms will be required before you may use the applicable service.
                        In cases where any of the Terms of this Agreement conflict with any terms and conditions of Additional Terms, 
                        the Additional Terms shall replace this agreement in relation to the relevant provisions.
                    </p>
                    <p>
                        <b>Updates</b><br/>
                        We may provide you with updates as they are made generally available by us in our sole
                        discretion. You acknowledge and agree that we may provide updates to you remotely and
                        automatically, including by accessing the device on which you use our Service. Any updates
                        provided or made available by us are part of our Service and will be subject to these Terms.
                    </p>
                    <h4>4. User Content</h4>
                    <p>
                        By submitting any content, including all communications, images, videos, sounds, content or part of the content of databases, 
                        databases, content and any other materials or information that you upload or transmit through the Services, including without 
                        limitation any text, voice transmissions or recordings, or gameplay clips (“User Content”), to our Service, you represent and 
                        warrant to us that the User Content is posted by you and that you are the exclusive owner of the User Content, or are otherwise 
                        licensed to use and sub-license the User Content and use of your User Content by us will not infringe or violate the rights of any third party.
                    </p>
                    <p>
                        By submitting any User Content to our Service, you automatically grant, and you represent and warrant that you have the right to grant, 
                        to us, and our licensees, affiliates and successors, a perpetual, worldwide, non-exclusive, royalty-free right and license to use, 
                        reproduce, display, and modify such User Content or incorporate into other works such User Content, and to grant and to authorize 
                        sub-licenses of the foregoing.
                    </p>
                    <p>
                        You are and will remain solely responsible for any User Content that you submit, post or
                        transmit via our Service. WE CANNOT GUARANTEE, AND ASSUME NO RESPONSIBILITY
                        FOR, VERIFYING THE ACCURACY OF THE INFORMATION PROVIDED BY OTHER USERS
                        OF THE SERVICE. 
                    </p>
                    <p>
                        You agree not to post or submit any content that:<br/>
                        ● publicly posts information that poses or creates a privacy or security risk to any person;<br/>
                        ● constitutes or promotes information that you know is false or misleading or promotes
                        illegal activities or conduct that is abusive, threatening, obscene, defamatory or
                        libellous-;<br/>
                        ● constitutes or promotes an illegal or unauthorized copy of another person's copyrighted
                        work;<br/>
                        ● involves the transmission of "junk mail," "chain letters," or unsolicited mass mailing,
                        instant messaging, "spimming," or "spamming" or unsolicited electronic commercial messages;<br/>
                        ● involves commercial activities and/or sales without prior written consent from us such as
                        contests, sweepstakes, barter, advertising, or pyramid schemes;<br/>
                        ● contains restricted or password only access pages or hidden pages or images (those not
                        linked to or from another accessible page);<br/>
                        ● furthers or promotes any criminal activity or enterprise or provides instructional
                        information about illegal activities including, but not limited to making or buying illegal
                        weapons, violating someone's privacy, or providing or creating computer viruses;<br/>
                        ● solicits passwords or personal identifying information for commercial or unlawful
                        purposes from other members;<br/>
                        ● includes a photograph or video of another person that you have posted without that
                        person's consent; or<br/>
                        ● violates the privacy rights, publicity rights, defamation rights, copyrights, trademark
                        rights, contract rights or any other rights of any person.    
                    </p>
                    <p>
                        The foregoing is a partial list of the kind of content that is illegal or prohibited on our Service. We reserve the right to investigate and take appropriate legal action in our sole discretion against anyone who 
                        violates this Section 4 or any other provision of these Terms, including without limitation, removing the offending 
                        communication from our Service, terminating the violator's membership and access to the Service (in accordance with section 6) 
                        and contacting and disclosing information to the relevant authorities.
                    </p>
                    <p>
                        We have no obligation to post any content that you or anyone else submits. While we do not
                        and cannot review every message or other material posted or sent by users of the Service, and are not 
                        responsible for any content of these messages or materials, we reserve the right, but are not obligated, 
                        to monitor, delete, move, or edit messages or materials, including without limitation advertisements, profiles, 
                        public postings and messages, that we, in our sole discretion, deem to violate these Terms or any applicable 
                        content guidelines, or to be otherwise unacceptable.
                    </p>
                    <p>
                        You acknowledge that such monitoring or recording can be made using software that may be installed 
                        when you download software to access or use our Service. Unless required by law, We assume no liability for 
                        any failure to remove, or any delay in removing, User Content and does not assume any responsibility or liability 
                        for the use and/or storage of User Content. To the maximum extent permitted by law, we are not liable to you if we 
                        exercise any of these rights, including cancellation of any type of membership.
                    </p>
                    <p>
                        You hereby grant us a royalty-free, perpetual, irrevocable, fully transferable and sublicensable, non-exclusive, 
                        worldwide right and license to use your User Content in any and all media in any manner and for any purpose 
                        (including without limitation commercial, advertising, and promotional purposes) to the greatest extent permitted 
                        by applicable law, including without limitation the rights to reproduce, copy, modify, perform, display, publish, 
                        distribute, broadcast, transmit, or otherwise communicate to the public, or create derivative works from or adapt, 
                        use or otherwise exploit by any means whether now known or unknown and without any further notice or compensation to you of any kind.
                    </p>
                    <p>
                        To the extent permitted by applicable law, you waive any moral rights of paternity, publication, reputation, or attribution 
                        with respect to our and other players’ use and enjoyment of User Content in connection with our Service. The license grant to us, 
                        and the above waiver of any applicable moral rights, survives any termination or expiration of the Terms.
                    </p>
                    <h4>5. Code of Conduct</h4>
                    <p>
                        Our Service is intended for use in a number of countries, including in the United States. You
                        agree to comply with all applicable laws when using our Service and dealing with other
                        members. This includes compliance with any and all applicable local, state, national and
                        international laws and regulations, including, but not limited to, United States export control laws. 
                        By using the Service, you represent that you have not been designated by the United States government as a 
                        "Specially Designated National" or other person to whom the provisions of the Service are prohibited. 
                        Registration for, and use of the Service are void where prohibited. You are responsible for determining whether the use of the Service is legal for your jurisdiction.
                    </p>
                    <p>
                        By using our Service, you agree to comply with the following;<br/>
                        ● You must provide accurate information regarding your identity when you register to become a member and use 
                        the Service and you must not use the Service under false pretences.
                        <br/>
                        ●Your account is for your personal use only. You may not authorise others to use your account, 
                        and you may not assign or otherwise transfer your account to any other person or entity. 
                        <br/>
                        ● We do not recognize or allow the transfer of accounts between players. You may not, and may 
                        not offer to, purchase, sell, gift, or trade any account. Any such attempt shall be null and 
                        void and may result in the termination and forfeiture of the account pursuant to section 6.
                        <br/>
                        ● You must not interfere with or damage our Service, including, without limitation, through
                        the use of viruses, registration bots, Trojan horses, harmful code, flood pings, denial of
                        service attacks, packet or IP spoofing, forged routing or electronic mail address
                        information or similar methods or technology, including methods that in any way
                        reproduces or circumvents the navigational structure or presentation of the Service or its
                        contents.
                    </p>
                    <p>
                        If you breach these provisions, we may terminate your membership and your access to the
                        Service for breach in accordance with section 6 of these Terms. Upon request by us, 
                        you agree to provide any further information to verify your identity and the accuracy of the personal 
                        information that you have provided. If you do not provide sufficient information for our verification purposes, 
                        we may terminate or suspend your membership or access to the Service in accordance with section 6.
                    </p>
                    <h4>6. Termination</h4>
                    <p>
                        We reserve the right, and may exercise our right to terminate your membership, this
                        Agreement and your access to our Service if:
                    </p>
                    <p>
                        a)  you breach these Terms;<br/>
                        b)  we consider (in our sole discretion) that you have violated or are likely to violate
                        any applicable laws;<br/>
                        c)  a serious complaint or multiple complaints are received about you from any
                        person;<br/>
                        d)  you impersonate another member or person; or<br/>
                        e)  we, in our sole discretion, deem your behaviour whilst using the Service or in
                        connection with your use of the Service, to be unacceptable.
                    </p>
                    <p>
                        YOU AGREE THAT ANY TERMINATION OF YOUR MEMBERSHIP AND ACCESS TO
                        OUR SERVICE BY US UNDER THESE TERMS PURSUANT TO SECTION 9, MAY BE
                        EFFECTED WITHOUT PRIOR NOTICE, AND ACKNOWLEDGE AND AGREE THAT
                        WE MAY IMMEDIATELY DEACTIVATE OR DELETE ANY OF YOUR ACCOUNTS
                        AND ALL RELATED INFORMATION AND FILES IN SUCH ACCOUNTS AND/OR BAR
                        ANY FURTHER ACCESS TO SUCH FILES OR OUR SERVICE. Members who have
                        registered under multiple aliases will have all of their aliases disabled. 
                        FURTHER, YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE TO 
                        YOU OR ANY THIRD PARTY FOR ANY COSTS OR DAMAGES OF ANY
                        KIND FOR OR RESULTING FROM ANY SUCH TERMINATION OF YOUR MEMBERSHIP OR ACCESS TO OUR SERVICE.
                    </p>
                    <p>
                        You may terminate your access to the Service at any time via our Websites or by
                        sending us written or email notice of termination or other such method as required in
                        your jurisdiction. IN THE EVENT THAT YOU TERMINATE THIS AGREEMENT, YOU
                        WILL NOT BE ENTITLED TO ANY REFUND OF UNUSED SUBSCRIPTION FEES,
                        unless you are terminating pursuant to section 6.1 or due to a change to these Terms
                        that you do not accept.
                    </p>
                    <h4>7. Virtual Currency and Items</h4>
                    <p>
                        While using our Services, you may have the option to purchase and use our virtual currency 
                        and in-game items from our online and in-app stores. Items from our store are not purchased by you, 
                        but rather are licensed to you under these Terms.
                        VIRTUAL CONTENTS, INCLUDING VIRTUAL CONTENTS EXCHANGED FOR PREPAID
                        PAYMENT INSTRUMENTS, ARE REGARDED AS GOODS OR SERVICES, AND ARE NOT
                        PREPAID PAYMENT INSTRUMENTS. Virtual currency and points have no “real-world” value
                        but may be exchanged for in-game items. REGARDLESS OF THE CONSIDERATION
                        OFFERED OR PAID IN EXCHANGE FOR VIRTUAL CURRENCY, YOU DO NOT HAVE ANY
                        OWNERSHIP RIGHTS IN THE ITEMS OBTAINED WITH VIRTUAL CURRENCY.
                    </p>
                    <p>
                        We may change the pricing for our Services at any time consistent with applicable law. Any
                        prices, quotations and descriptions made or referred to on our Services do not constitute an offer and may be 
                        withdrawn or revised at any time before our express acceptance of your order.By using our service, you agree that 
                        you will pay all charges, fees, and applicable taxes incurred by 
                        you or anyone else using your account or resulting from use of the Services on your device when such charges occur, 
                        to the extent permitted by law. Price and availability of items are subject to change without notice, consistent with applicable law.
                    </p>
                    <p>
                        We reserve the right to notify you of any errors that may exist in descriptions of products or
                        pricing prior to the dispatch of the product. You may choose whether or not to complete the order, 
                        but if you do, you acknowledge that the product or service will be provided in accordance with the revised description or corrected price.
                    </p>
                    <p>
                        We may offer different payment methods, such as credit card, direct debit, PayPal, etc.,
                        By using third-party payment and billing providers, such as PayPal, that provider’s additional
                        terms, conditions, and costs, apply. You have the responsibility and obligation to pay all costs 
                        including taxes that may apply with the purchase of the items.
                    </p>
                    <p>
                        YOU UNDERSTAND AND AGREE THAT WE DO NOT AND WILL NOT OFFER REFUNDS
                        FOR VIRTUAL CURRENCY OR DIGITAL, IN-GAME ITEMS UNDER ANY CIRCUMSTANCES,
                        INCLUDING WITHOUT LIMITATION TERMINATION OR EXPIRATION OF YOUR ACCOUNT,
                        THESE TERMS, OR THE SERVICES, EXCEPT AS EXPRESSLY REQUIRED BY
                        APPLICABLE LAW.
                    </p>
                    <p>
                        If an account is permanently banned, all content licenses and virtual
                        currency balances associated with the suspended account are forfeited. 
                        We have no obligation or responsibility to, and will not reimburse you for any virtual currency, points, items, 
                        or experience lost due to your violation of these Terms.
                    </p>
                    <p>
                        You accept and acknowledge that the items you purchase may expire. 
                        All items you purchase using virtual currency or points will be visible on your account until either the expiry of the item, 
                        termination or expiry of your account, these terms or the services, whichever occurs earliest.
                    </p>
                    <p>
                        We reserve the right to suspend and/or terminate your account for reasons of charges you
                        authorize us to make being unable to be processes and/or returned to us unpaid or refunded. 
                        If such an event occurs, you are responsible for immediately making payment to us for the owing 
                        amount through the use of another credit card or other form of payment mechanism. 
                        We will not be responsible or liable for any credit card or bank-related charges or fees related to 
                        any of your transactions. We reserve the right, without giving notice, to limit the order quantity 
                        on any item or to refuse to provide you with any item. You may thus be required to verify your transaction 
                        information prior to our acceptance.
                    </p>
                    <p>
                        Notwithstanding these Terms, the laws in your country may apply to the purchase of virtual
                        goods and services supplied by us to you and you may have rights or remedies as set out in
                        such laws that apply in addition to, or, to the extent that they are inconsistent, instead of, the rights or remedies set out in these Terms.
                    </p>
                    <h4>8. Intellectual Property and Claims of Infringement</h4>
                    <p>
                        Paths owns all intellectual property rights and other proprietary rights in our Websites (including text, 
                        graphics, logos, icons and sound recordings but excluding member content) and the software and other material 
                        underlying and forming part of our Service and our Websites.
                    </p>
                    <p>
                        You may not without our prior written permission, in any form or by any means:
                        adapt, reproduce, copy, store, distribute, print, display, perform, publish or create derivative
                        works from any part of our Website; or commercialise, copy or on-sell any information or items obtained from any part of our Website.
                    </p>
                    <p>
                        We respect the intellectual property rights of others. If you have reason to believe that your
                        copyrighted work or other intellectual property has been infringed and is accessible through our
                        services, you may notify us by contacting us and providing:
                    </p>
                    <p>
                        ● Identification of the copyrighted work or intellectual property, or a representative list of
                        works or property claimed to have been infringed.<br/>
                        ● Identification of the allegedly infringing material and information reasonably sufficient to
                        permit us to locate the material.<br/>
                        ● Your name, address and daytime telephone number, and an e-mail address if available,
                        so that we may contact you if necessary.<br/>
                        ● A statement that the information in the notification is accurate, and under penalty of
                        perjury, that the complaining party is authorized to act on behalf of the owner of an
                        exclusive right that is allegedly infringed.<br/>
                        ● A statement that you have a good faith belief that the use of the material in the manner
                        complained of is not authorized by the owner of the intellectual property, its agent, or the
                        law.<br/>
                        ● A physical or electronic signature of a person authorized to act on behalf of the owner of
                        the exclusive right that is allegedly infringed.
                    </p>
                    <p>
                        If the content is removed, the party that posted the content may receive a notice that it was 
                        removed because of claimed infringement. If that party believes that the content shouldn’t have been removed, 
                        they may be able to submit a counter-notification under applicable law. We terminate the privileges of users 
                        who repeatedly infringe intellectual property laws.
                    </p>
                    <h4>9. Resolution of Disputes</h4>
                    <p>
                        As Paths is not a party to any dispute between you and another member, you agree:<br/><br/>
                        not to involve, or attempt to involve, Paths in any dispute or in the resolution of disputes that arise 
                        between you and another member as a result of our Service provided; and
                        not to use our Service to engage in or attempt to resolve the dispute.
                    </p>
                    <p>
                        You acknowledge that neither you nor us, will bring nor participate in any class action or other class 
                        proceeding in connection with any dispute with the other party. Further, neither you nor we agree to class 
                        arbitration or any arbitration where a person brings a dispute as a representative of the other person.
                    </p>
                    <p>
                        You agree that no joint venture, partnership, employment, or agency relationship exists between you and us 
                        as a result of these Terms or your access to and use of the Services.
                    </p>
                    <h4>10. Links, Advertisements and Non-Commercial Use</h4>
                    <p>
                        Our Service may occasionally contain links and advertisements to third party websites,
                        companies and products. These links and advertisements are provided solely as a convenience 
                        to you and not as an endorsement of the contents, value, quality, safety, products, or accuracy on such third-party websites.
                    </p>
                    <p>
                        We are not responsible for the content, value, quality, safety, products or accuracy of linked
                        third-party websites, companies and products; and do not make any representations regarding the content, 
                        value, quality, safety, products, or accuracy of materials on such third party websites. 
                        If you decide to access, use, or purchase from linked third-party websites, companies or products, you do so at your own risk.
                    </p>
                    <p>
                        Our Service is for personal use only and must not be used in connection with any commercial purposes. 
                        Organizations, companies, and/or businesses must not use our Service for any purpose. 
                        USE OF OUR SERVICE IS WITH OUR PERMISSION, WHICH MAY BE REVOKED AT ANY TIME, FOR ANY REASON, IN OUR SOLE DISCRETION.
                    </p>
                    <h4>11. Disclaimer</h4>
                    <p>
                        You expressly understand and agree that:<br/>
                        YOUR USE OF OUR SERVICE IS AT YOUR SOLE RISK. Paths EXPRESSLY DISCLAIMS, TO
                        THE MAXIMUM EXTENT PERMITTED BY LAW, ALL REPRESENTATIONS, WARRANTIES
                        OR GUARANTEES, EITHER EXPRESSED EXPRESSED OR IMPLIED, INCLUDING, BUT
                        NOT LIMITED TO, ANY REPRESENTATIONS, WARRANTIES OR GUARANTEES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                        NON-INFRINGEMENT.
                    </p>
                    <p>
                        Paths makes no representation, warranty or guarantee, to the maximum extent permitted by law, that<br/><br/>
                        a) our Service provided will be uninterrupted, timely, secure, or error free,<br/>
                        b) the information provided on our Websites is error-free or reliable,<br/>
                        c) the quality of any products or services obtained through our Service will meet your
                        requirements, or<br/>
                        d) members are who they say they are.
                    </p>
                    <p>
                        YOU DO NOT ENTER INTO THIS AGREEMENT IN RELIANCE ON ANY REPRESENTATION,
                        WARRANTY, GUARANTEE OR OTHER TERM, EXCEPT AS EXPRESSLY SET OUT IN
                        THESE TERMS.
                    </p>
                    <p>
                        No advice or information that is obtained by you from Paths or otherwise shall create any
                        representation, warranty or guarantee not expressly stated in these Terms.
                    </p>
                    <p>
                        Responsibility for the content of advertisements appearing on our Service (including hyperlinks to advertisers' own websites) 
                        rests solely with the advertisers. The placement of such advertisements on our Service does not constitute our recommendation 
                        or endorsement of the advertised product or service. Each advertiser is solely responsible for any and all representations 
                        made in connection with its advertisement.
                    </p>
                    <h4>12. Limitation of Liability</h4>
                    <p>
                        YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT
                        PERMITTED BY LAW:
                    </p>
                    <p>
                        Neither party shall be liable to the other under any circumstances for any indirect, incidental, special, consequential 
                        or exemplary loss or damages, including but not limited to damages for loss of profit, goodwill, use, data, or other 
                        intangible losses that result from the Service, or the inability to access the Service.
                    </p>
                    <p>
                        Paths shall not be liable for the cost of procurement of substitute goods or services resulting from services provided by us.
                    </p>
                    <p>
                        Paths shall not be liable for the conduct or statements of members or third parties in relation to the Service.
                    </p>
                    <p>
                        None of the disclaimers, limitations or exclusions set out in these Terms will limit or exclude any form of 
                        liability to the extent such liability cannot be limited or excluded under applicable law, for example, under 
                        the Consumer Guarantees Act 1993 (New Zealand), the Fair Trading Act 1986
                        (New Zealand) or any other applicable consumer protection legislation. 
                    </p>
                    <h4>13. Indemnity</h4>
                    <p>
                        YOU AGREE TO INDEMNIFY Paths ALONG WITH OUR REPRESENTATIVES, MANAGERS,
                        PARTNERS, SHAREHOLDERS, JOINT VENTURERS, THIRD-PARTY CONTRACTORS,
                        EMPLOYEES, LICENSEES, LICENSORS, ADVERTISERS, AND AGENTS, FULLY FROM
                        AND AGAINST ALL CLAIMS, LIABILITY, LOSS, COSTS AND EXPENSES (INCLUDING THE
                        FULL AMOUNT OF LEGAL COSTS) INCURRED OR SUFFERED BY Paths IN RESPECT OF
                        OR IN CONNECTION WITH:
                    </p>
                    <p>
                        a) information contained in your profile;<br/>
                        b) any material, including (without limitation) photos and messages, you post to or enter on
                        our Websites or using the Service;<br/>
                        c) your communications with or behaviour towards other members;<br/>
                        d) any death, personal injury or damage to property you may cause;<br/>
                        e) your breach of these Terms or any applicable laws.
                    </p>
                    <h4>14. General Provisions:</h4>
                    <p>
                        <b>Captions and Headings:</b><br/>
                        The captions and section and paragraph headings used in the Terms are inserted for
                        convenience only and shall not affect the meaning or interpretation of the Terms.<br/><br/>
                        <b>System integrity:</b><br/>
                        Paths will use its reasonable endeavours to ensure the availability of our Service, subject to any
                        downtime required for maintenance. However, to the maximum extent permitted by law, we take
                        no responsibility for any system unavailability, or for any loss that is incurred as a result of our
                        Service being unavailable. Further, to the maximum extent permitted by law, we assume no
                        responsibility for the corruption of any data or information held by us.<br/><br/>
                        <b>Force Majeure:</b><br/>
                        We have no liability for any lack of performance, unavailability or failure of our Service, or for
                        any failure on our part to comply with these Terms where the same arises from any cause
                        reasonably beyond the control of Paths.<br/><br/>
                        <b>Non Waiver:</b><br/>
                        If we do not exercise or enforce any right available to us under these Terms, it does not
                        constitute a waiver of those rights.<br/><br/>
                        <b>Partial Invalidity:</b><br/>
                        If any provision of these Terms become or are held to be invalid, unenforceable or illegal for any
                        reason and in any respect, that provision shall be severed from the remaining terms and
                        conditions, which shall continue in full force and effect.<br/><br/>
                        <b>Governing Law:</b><br/>
                        These Terms and your membership are governed by the laws of New Zealand. You submit to
                        the non-exclusive jurisdiction of the Courts of New Zealand. You agree that the United Nations
                        Convention on Contracts for the International Sale of Goods does not apply.<br/><br/>
                        <b>Entire agreement:</b><br/>
                        These Terms, our privacy policy and the code of ethics supersede all previous conditions,
                        understandings, commitments, agreements and representations whatsoever, whether oral or
                        written; and constitutes the entire agreement between the parties relating to the subject matter
                        of these Terms.<br/><br/>
                        <b>Assignment:</b><br/>
                        This agreement may not be assigned, in whole or part, whether voluntarily, by operation of law,
                        or otherwise, by you without the prior written consent of Paths. We may assign, license, delegate
                        or otherwise transfer its rights or obligations hereunder to any third party without restriction.
                        Subject to the preceding sentences, the rights and liabilities of the parties hereto are binding on,
                        and shall inure to the benefit of, the parties and their respective successors and permitted
                        assigns. Any attempted assignment other than in accordance with this Section shall be null and
                        void.
                    </p>
                    <p>
                        <h4>Privacy:</h4>
                        Please refer to our privacy policy.<br/><br/>
                        <h4>Acknowledgement:</h4>
                        You represent and warrant that you:<br/>
                    </p>
                    <p>
                        are not located in a country that is subject to a US Government embargo or that has been
                        designated by the US Government as a 'terrorist supporting' country; and
                        are not listed on any US Government list of prohibited or restricted persons.
                    </p>
                    <p>
                        If you have any questions or concerns about our terms and conditions, the services of our
                        Website, you may contact a representative of our company by calling us on (00649) 365 1088,
                        using the Contact Form within the App or by regular mail: PO Box 5800, WELLESLEY ST,
                        Auckland 1141, New Zealand.
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default TermsPage